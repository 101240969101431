<template>
  <div class="ShiftMonitoring">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="w-full absolute -mt-6">
      <div class="px-4 md:px-10 mx-auto">
        <HelpBar @getData="getData" />
      </div>
      <div v-if="Object.keys(bookings).length == 0">
        <h3 class="text-3xl text-center pt-20 font-semibold">
          {{ $t("There are no bookings within the period selected") }}
        </h3>
        <h3 class="text-xl text-center font-semibold">
          {{ getDateOnly(dateRange[0]) }} -
          {{ getDateOnly(dateRange[1]) }}
        </h3>
      </div>
      <div
        class="px-4 md:px-10 mx-auto w-full"
        v-for="([date, booking], i) in Object.entries(bookings)"
        :key="`${booking}-${i}`"
      >
        <div class="flex flex-wrap mt-10">
          <SlideUp>
            <ListTable
              :tableHeight="250"
              :tableName="date"
              :headers="headers"
              :tableData="booking"
              :searchable="false"
            />
          </SlideUp>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="showPreview"
      :fullscreen="true"
      :show-close="false"
    >
      <ViewCheckListResult
        @close="showPreview = false"
        :bike_id="current_bike_id"
        :items="currentItem"
        :title="checkListName"
      />
    </el-dialog>
  </div>
</template>

<script>
import SlideUp from "@/components/transitions/SlideUp.vue";
import ListTable from "@/components/ListTable.vue";
import HelpBar from "../components/HelpBar.vue";
import formatting from "../mixins/formatting";
import { groupByDate } from "../store/helpers";
import ViewCheckListResult from "@/components/checklist/ViewCheckListResult.vue";

export default {
  name: "Bookings",
  mixins: [formatting],
  components: {
    SlideUp,
    ListTable,
    ViewCheckListResult,
    HelpBar
  },
  data() {
    return {
      showPreview: false,
      currentItem: null,
      current_bike_id: null,
      checkListName: null,
      headers: [
        {
          name: "asset",
          key: "bike.name",
          type: "link",
          linkInfo: { name: "Fleet-Detail", routeKey: "id", varKey: "bike.id" }
        },
        {
          name: "user",
          key: "user.name",
          type: "link",
          linkInfo: { name: "User-Detail", routeKey: "id", varKey: "user.id" }
        },
        { name: "booked at", key: "grabbed_at", type: "dateTime" },
        { name: "returned at", key: "returned_at", type: "dateTime" },
        {
          name: "start checklist",
          type: "modal",
          key: "start_checklist_result.id",
          rowDisplayName: "view results",
          modalClick: item => {
            if (item?.start_checklist_result?.checklist_responses) {
              this.showPreview = true;
              this.current_bike_id = item?.start_checklist_result?.bike_id;
              this.checkListName =
                item?.start_checklist_result?.checklist?.name;
              this.currentItem =
                item?.start_checklist_result?.checklist_responses;
            }
          }
        },
        {
          name: "end checklist",
          type: "modal",
          key: "end_checklist_result.id",
          rowDisplayName: "view results",
          modalClick: item => {
            if (item?.end_checklist_result?.checklist_responses) {
              this.showPreview = true;
              this.current_bike_id = item?.end_checklist_result?.bike_id;
              this.checkListName = item?.end_checklist_result?.checklist?.name;
              this.currentItem =
                item?.end_checklist_result?.checklist_responses;
            }
          }
        },
        { name: "notes", key: "notes", type: "" }
        // { name: "QR", key: "qr_id", type: "" }
      ]
    };
  },
  computed: {
    dateRange() {
      return this.$store.state.dateRange
        ? this.$store.state.dateRange
        : [null, null];
    },
    bookings() {
      const checkouts = this.$store.state.checkouts?.checkouts
        ? this.$store.state.checkouts?.checkouts
        : [];
      return groupByDate(checkouts, "grabbed_at");
    }
  },
  methods: {
    getData() {
      this.$store.dispatch("checkouts/getAllCheckouts");
    }
  },
  mounted() {
    this.getData();
  }
};
</script>
